import ky, { HTTPError } from 'ky'
import type { KyResponse, SearchParamsOption } from 'ky'
import { useModalsStore } from '@/stores/modals'
import { useUserAuthStore } from '@/stores/user-auth'
import * as errorTracker from '@/utils/error-tracker'
import { addArraySyntax } from '@/utils/helpers'

export type FbHttpMethod = 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete'

export type FbApiParams = {
  method?: FbHttpMethod
  headers?: Record<string, string>
  body?: any // for multipart/form-data
  json?: any // for application/json

  // copied from ky, needs to match
  searchParams?: SearchParamsOption

  // abort controller signal
  signal?: AbortSignal

  // fb specific params
  skipErrorModal?: boolean
  prefix?: 'api' | 'fbadmin' | 'web'
  attemptNum?: number
}

export type FbApiResponse = {
  raw: KyResponse
  status: number
  body: any
}

export type FbApiInstance = {
  (url: string, params?: FbApiParams): Promise<FbApiResponse>
  get(url: string, params?: FbApiParams): Promise<FbApiResponse>
  post(url: string, params?: FbApiParams): Promise<FbApiResponse>
  put(url: string, params?: FbApiParams): Promise<FbApiResponse>
  patch(url: string, params?: FbApiParams): Promise<FbApiResponse>
  delete(url: string, params?: FbApiParams): Promise<FbApiResponse>
  head(url: string, params?: FbApiParams): Promise<FbApiResponse>
}

/**
 * Custom error class for fbApi
 */
export class FbApiError extends Error {
  raw: KyResponse
  status: number
  body: any

  constructor(message: string, { raw, status, body }: FbApiResponse) {
    super(message)
    this.name = 'FbApiError'
    this.raw = raw
    this.status = status
    this.body = body
  }
}

/**
 * Primary API wrapper around ky. Intended to be used for fitnessblender API
 * and not third party APIs.
 *
 * If 400 csrf token error w/ logged-in user action, request will retry once.
 */
function createFbApiInstance(): FbApiInstance {
  /**
   * Attach headers to all fitness blender API requests.
   */
  const fbKy = ky.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          // using csrf + web middleware (and not api middleware) in Laravel
          const userAuthStore = useUserAuthStore()
          request.headers.set('X-CSRF-TOKEN', userAuthStore.csrfToken)

          // set for legacy reasons if Laravel uses $request->ajax() instead of $request->expectsJson()
          request.headers.set('X-Requested-With', 'XMLHttpRequest')
        },
      ],
    },
  })

  const fbApi = async function (
    url: string,
    {
      method = 'get',
      headers,
      body,
      json,
      searchParams,
      signal,
      // allows skipping most error modals (200 + status = error, 4xx and 5xx)
      // 429 will always show
      skipErrorModal = false,
      prefix = 'api', // api|fbadmin|web
      attemptNum = 1, // used for retry token failures
    }: FbApiParams = {},
  ): Promise<FbApiResponse> {
    const modalsStore = useModalsStore()
    const userAuthStore = useUserAuthStore()

    // default to api  prefix if not set
    let endpointPrefix = '/api/v1'

    // laravel fbadmin api routes
    if (prefix === 'fbadmin') {
      endpointPrefix = '/fbadmin/api/v1'
    }

    // laravel web routes, used rarely
    if (prefix === 'web') {
      endpointPrefix = ''
    }

    const endpoint = `${endpointPrefix}${url}`

    // ky options
    const kyOptions = {
      method,
      headers,
      body,
      json,
      searchParams,
      signal,
      retry: 0, // disable ky built in retries
      timeout: false as false, // false to disable
    }

    try {
      const res = await fbKy(endpoint, kyOptions)
      const responseBody = (await res.json()) as any

      /* STATUS CODE 2xx + ERROR */
      // some old APIs return status 'error' but with 2xx
      // throw error unless skipping error modal, which will be a success
      if (!skipErrorModal && responseBody.status === 'error') {
        // if error just show generic error modal and don't process data
        modalsStore.showErrorModal()

        // send to front end error tracker
        errorTracker.captureMessage({
          msg: `Vue3 API Error on Success`,
          extras: {
            url: res.url,
            statusCode: res.status,
            statusText: res.statusText,
            message: responseBody && responseBody.message ? responseBody.message : '',
          },
        })

        // catch and re-throw below
        throw new FbApiError('API Error on Success', {
          raw: res,
          status: res.status,
          body: responseBody,
        })
      }

      // actual success
      return {
        raw: res,
        status: res.status,
        body: responseBody,
      }
    } catch (err) {
      // if "success on error", just re-through
      if (err instanceof FbApiError) {
        throw err
      }

      // ky throws HTTPError, if we somehow an error other than HTTPError, then re-throw it.
      // EX: AbortController.signal with throw DOMException with name 'AbortError'
      if (!(err instanceof HTTPError)) {
        throw err
      }

      // have to re-parse json from error
      const { response } = err
      let responseBody = null

      try {
        responseBody = (await err.response.json()) as any
      } catch (err) {
        console.error('error response could not be parsed as json')
      }
      // end parse

      // if delete request + 404, then skip error tracking and modal
      if (method === 'delete' && responseBody && response.status === 404) {
        throw new FbApiError('Delete status 404', {
          raw: response,
          status: response.status,
          body: responseBody,
        })
      }

      // force attempts to be set
      const attempts = attemptNum ?? 1

      // retry request if CSRF token error (only once)
      // seems to happen if logged-in user has long-running tab
      // NOTE: restrict to logged in only, anon users cannot hit the retry endpoint
      if (
        userAuthStore.user.isLoggedIn &&
        response.status === 400 &&
        responseBody &&
        responseBody.message === 'Token Error' &&
        attempts === 1
      ) {
        console.warn('token error, retrying')

        return retryCsrfToken(url, {
          method,
          headers,
          body,
          json,
          searchParams,
          skipErrorModal,
          prefix,
          attemptNum: attempts,
        })
      }

      // error modal
      // TODO: add 401 login expired modal
      if (response.status === 429) {
        // if throttled, always show throttled modal
        modalsStore.showErrorModal('throttled')
      } else if (!skipErrorModal) {
        modalsStore.showErrorModal()
      }
      // end error modal

      // send to front end error tracker
      errorTracker.captureMessage({
        msg: `Vue3 API Error`,
        extras: {
          url: response.url,
          statusCode: response.status,
          statusText: response.statusText,
          attemptNum: attempts,
          message: responseBody && responseBody.message ? responseBody.message : '',
        },
      })

      // final throw
      throw new FbApiError('API Error', {
        raw: response,
        status: response.status,
        body: responseBody,
      })
    }
    // end catch
  }

  // assign helper method for each http method
  const methods: FbHttpMethod[] = ['get', 'post', 'put', 'patch', 'delete', 'head']

  for (const method of methods) {
    ;(fbApi as FbApiInstance)[method] = async function (
      url: string,
      params: FbApiParams = {},
    ): Promise<FbApiResponse> {
      return fbApi(url, {
        ...params,
        method,
      })
    }
  }
  // end assign helper method`

  return fbApi as FbApiInstance
}

const fbApi = createFbApiInstance()
export { fbApi }

/**
 * Retry any API request that failed due to CSRF error only once.
 *
 * Must be POST request to take advantage of session cookie
 * samesite: lax value.
 *
 * @see https://stackoverflow.com/questions/59990864/what-is-difference-between-samesite-lax-and-samesite-strict
 */
async function retryCsrfToken(url: string, params: FbApiParams) {
  const userAuthStore = useUserAuthStore()

  // no try catch as an error should fail and return
  // should be unauthenticated 401 if that happens
  const res = await fbApi.post('/my/token', {
    attemptNum: 2, // set only to skip any retry for this request
  })

  // update store
  userAuthStore.updateCsrfToken(res.body.data)
  // userAuthStore.updateCsrfToken('still-invalid');

  // set attempt number to 2 so no additional retries
  return fbApi(url, {
    ...params,
    attemptNum: 2,
  })
}

type SearchParamOptions = {
  arrayBrackets: boolean
}

/**
 * Helper to convert vue-router query params to URLSearchParams for ky searchParams.
 *
 * NOTE: Does not support nested objects.
 */
export function objToSearchParams(
  searchParams: Record<string, any>,
  options: SearchParamOptions = { arrayBrackets: true },
): URLSearchParams {
  let _searchParams = searchParams

  // useful for php backends expecting brackets like type[]=
  if (options.arrayBrackets) {
    _searchParams = addArraySyntax(_searchParams)
  }

  const convertedParams = Object.entries(_searchParams).flatMap(([key, values]) => {
    if (Array.isArray(values)) {
      return values.map((value) => [key, value])
    }

    // simple key value pair
    return [[key, values]]
  })
  return new URLSearchParams(convertedParams)
}

export function isAbortError(err: any): boolean {
  return err && err.name === 'AbortError'
}

/**
 * Equality helper, could use lodash isEqual but only dealing
 * a few arrays and string values
 */
export function isSearchParamsEqual(
  searchParams: Record<string, string | string[]>,
  compareParams: Record<string, string | string[]>,
): boolean {
  if (Object.keys(searchParams).length !== Object.keys(compareParams).length) {
    return false
  }

  return Object.entries(searchParams).reduce((acc, [paramKey, paramVal]) => {
    const defaultParamVal = compareParams[paramKey]

    // skip if we already hit a false check
    if (!acc) {
      return false
    }

    if (!defaultParamVal) {
      return false
    }

    // arr values
    if (Array.isArray(paramVal) && Array.isArray(defaultParamVal)) {
      if (!defaultParamVal || paramVal.length !== defaultParamVal.length) {
        return false
      }

      return paramVal.every((val: string) => {
        return defaultParamVal.includes(val)
      })
    }

    // non arr values
    return paramVal === defaultParamVal
  }, true)
}
