import { defineStore } from 'pinia'
import type {
  CheckboxFilter,
  CheckboxFilterItem,
  CheckboxUrlParameterName,
  DropdownFilter,
  InitCheckboxFilter,
  InitCheckboxFilterItem,
  PlusPass,
  QueryStringFilters,
  RangeFilter,
  RangeUrlParameterName,
  SearchInit,
  SearchState,
  SearchTag,
  SortOption,
  TriStateCheckboxState,
} from '@/types/search-types'

export const useSearchStore = defineStore('search', {
  state: (): SearchState => ({
    checkboxFilters: null,
    rangeFilters: null,
    dropdownFilters: null,
    enableFacets: false,
    allowEmptyFacets: false,
    keywords: '', // always just '' if nothing, not null
    sortOptions: [],
    sortSelected: 'newest',
    sortDefault: 'newest',
    similarToId: null, // used just on "find similar" view
    tags: [],

    // TODO: filters - beta end - refactor to not be so silly and toggle bool to reset
    // used to force clear a surprise me result
    clearSurpriseMe: false,

    filtersOpen: false,
    filterGroupingsExpanded: {},

    // data from window.Laravel initial page load (pagination, first results)
    // can be anything >.<
    init: window.Laravel && window.Laravel.searchInit ? window.Laravel.searchInit : {},
  }),

  getters: {
    /**
     * See if any filters are in use
     */
    hasAny(state: SearchState): boolean {
      return !!state.tags.length
    },

    hasKeywords(state: SearchState): boolean {
      return state.keywords !== ''
    },

    /**
     * Determine if filters are active based on params.
     *
     * USE CASES
     * searchStore.hasFilters({ range: ['duration'] })
     * - include a key with an array of names to check and see if any are selected
     *
     * searchStore.hasFilters({ exclude: ['bodyfocus_ids', 'duration']
     * - only include exclude key with list of items to exclude, all others keys will be checked
     *
     */
    hasFilters:
      (state: SearchState) =>
      (
        filters: Partial<Record<'checkbox' | 'dropdown' | 'range' | 'exclude', string | string[]>>,
      ): boolean => {
        if (!state.tags.length) {
          return false
        }

        // exclude only checks
        if (Object.keys(filters).length === 1 && filters.exclude) {
          // force array
          const excludeArr = Array.isArray(filters.exclude) ? filters.exclude : [filters.exclude]

          return state.tags.some((tag) => {
            return !excludeArr.includes(tag.filterName)
          })
        }

        // include checks
        return Object.entries(filters).some(([type, filterKeys]) => {
          // force array
          const filterKeyArr = Array.isArray(filterKeys) ? filterKeys : [filterKeys]
          const excludeArr = Array.isArray(filters.exclude) ? filters.exclude : [filters.exclude]

          return state.tags.some((tag) => {
            return (
              tag.type === type &&
              filterKeyArr.includes(tag.filterName) &&
              !excludeArr.includes(tag.filterName)
            )
          })
        })
      },

    // TODO: filters - beta end - audit and delete
    // helper to see if any checkbox, range, or dropdown filters are in use
    legacyHasFilters(state: SearchState): boolean {
      // check checkboxes first
      if (state.checkboxFilters) {
        const checkboxesInUse = Object.keys(state.checkboxFilters).reduce(
          (acc: boolean, filterKey: string) => {
            // if already truthy, skip and just return
            if (acc) {
              return true
            }

            // skip keys that have other getters already
            if (['team_member_ids'].includes(filterKey)) {
              return acc
            }

            const filter = state.checkboxFilters ? state.checkboxFilters[filterKey] : null

            if (!filter) {
              return false
            }

            // see if an item is checked
            return filter.items.some((item: CheckboxFilterItem) => item.state)
          },
          false,
        )

        // if a filter already used... let's stop here, no more checkes needed
        if (checkboxesInUse) {
          return checkboxesInUse
        }
      }
      // end checkboxes

      // check ranges
      if (state.rangeFilters) {
        const rangesInUse = Object.entries(state.rangeFilters).reduce(
          (acc, [filterKey, filter]): boolean => {
            // if already truthy, skip and just return
            if (acc) {
              return true
            }

            // see if range min / max has a value
            if (filter && ((filter.min && filter.min.value) || (filter.max && filter.max.value))) {
              return true
            }

            return false
          },
          false,
        )

        // if a filter already used... let's stop here, no more checkes needed
        if (rangesInUse) {
          return rangesInUse
        }
      }
      // end ranges

      // check dropdowns
      if (state.dropdownFilters) {
        const dropdownsInUse = Object.entries(state.dropdownFilters).reduce(
          (acc, [filterKey, filter]): boolean => {
            // if already truthy, skip and just return
            if (acc) {
              return true
            }

            // skip keys w/o indicator
            if (filterKey === 'notificationStatus' || filterKey === 'tags') {
              return acc
            }

            // single value drop downs
            if (filter && !filter.multiple && filter.value) {
              return true
            }

            // multiple value drop downs
            if (filter && filter.multiple) {
              return !!(Array.isArray(filter.value) && filter.value.length)
            }

            return false
          },
          false,
        )

        // if a filter already used... let's stop here, no more checkes needed
        if (dropdownsInUse) {
          return dropdownsInUse
        }
      }
      // end dropdowns

      // nothing in use
      return false
    },

    // helper method, not really a getter and doesn't use state
    videoPrivateFilters: (state: SearchState) => (): Record<string, string> => {
      return {
        tags: 'Tags',
        history: 'Completion',
      }
    },

    // build a tag item for the keywords
    keywordsTagItem(state: SearchState): SearchTag {
      return {
        id: 'keywords',
        filterName: 'keywords',
        tagLabel: state.keywords,
        state: Boolean(state.keywords),
        type: 'keywords',
      }
    },

    // build a TagItem for a specific rangeFilter
    rangeTagItem:
      (state: SearchState) =>
      ({
        filterName,
        minValue,
        maxValue,
        units,
      }: {
        filterName: string
        minValue: string
        maxValue: string
        units: string
      }): SearchTag => {
        let minLabel = minValue || '0'
        if (minLabel === 'min') {
          minLabel = '0'
        }

        let maxLabel = maxValue || 'Max'
        if (maxLabel === 'max') {
          maxLabel = 'Max'
        }

        const tagLabel =
          minValue === maxValue ? minLabel + ' ' + units : minLabel + ' – ' + maxLabel + ' ' + units

        return {
          id: filterName,
          filterName: filterName,
          tagLabel: tagLabel,
          state: Boolean(minValue || maxValue),
          type: 'range',
        }
      },

    // build a tag item for dropdowns (used in two places: init URL and clicking drop downs)
    dropdownTagItem:
      (state: SearchState) =>
      ({
        id,
        filterName, // dropdownFilters[filterName] not dropdownFilter.name
        tagLabel,
        value,
      }: {
        id?: string
        filterName: string
        tagLabel: string
        value: string
      }): SearchTag => {
        return {
          // default to custom id (multiple), otherwise just use passed in name (single)
          id: id ? id : filterName,
          filterName: filterName,
          tagLabel: tagLabel,
          value: value,
          state: !!value,
          type: 'dropdown',
        }
      },

    // build a tag item for checkboxes
    checkboxTagItem:
      (state: SearchState) =>
      ({
        id,
        filterName, // dropdownFilters[filterName] not dropdownFilter.name
        tagLabel,
        value,
        state,
      }: {
        id?: string
        filterName: string
        tagLabel: string
        value: string | number
        state: string
      }): SearchTag => {
        return {
          // default to custom id (multiple), otherwise just use passed in name (single)
          id: id ? id : filterName,
          filterName: filterName,
          tagLabel: tagLabel,
          value: value,
          state: state,
          type: 'checkbox',
        }
      },

    // gather params to be passed up via API call
    // NOTE: does not include pagination
    apiParameters(state: SearchState): Record<string, string | string[]> {
      const params: Record<string, string | string[]> = {}

      // ranges
      if (state.rangeFilters) {
        Object.entries(state.rangeFilters).forEach(([key, rangeFilter]) => {
          const minValue = rangeFilter.min.value
          // only add if it is not a default value
          if (!(['min', ''].indexOf(minValue) >= 0)) {
            params[rangeFilter.min.name] = minValue
          }

          const maxValue = rangeFilter.max.value
          if (!(['max', ''].indexOf(maxValue) >= 0)) {
            params[rangeFilter.max.name] = maxValue
          }
        })
      }

      // keywords
      if (this.hasKeywords) {
        params.keywords = state.keywords
      }

      // sort
      if (state.sortSelected !== state.sortDefault) {
        params.sort = state.sortSelected
      }

      // similar id, only used on similar results view, force string
      if (state.similarToId) {
        params.similarto = `${state.similarToId}`
      }

      // checkboxes (include and exclude are in separate url vars)
      if (state.checkboxFilters) {
        Object.entries(state.checkboxFilters).forEach(([key, checkboxFilter]) => {
          // get all the checkboxes that are checked
          // force values to be strings (to match URL)
          const includedItems = checkboxFilter.items
            .filter((item) => item.state === 'include')
            .map((item) => `${item.value}`)

          // only add if it at least one box is checked
          if (includedItems.length > 0) {
            params[checkboxFilter.name] = includedItems
          }

          // get all the checkboxes that are checked
          // force values to be strings (to match URL)
          const excludedItems = checkboxFilter.items
            .filter((item) => item.state === 'exclude')
            .map((item) => `${item.value}`)

          // console.log('apiParameters', checkboxFilter, includedItems, excludedItems);

          // only add if it at least one box is checked
          if (excludedItems.length > 0) {
            params[`x${checkboxFilter.name}`] = excludedItems
          }
        })
      }

      // dropdowns (some could be array so filter those out)
      if (state.dropdownFilters) {
        Object.entries(state.dropdownFilters).forEach(([key, dropdownFilter]) => {
          // multiple value drop downs
          if (
            dropdownFilter.multiple &&
            Array.isArray(dropdownFilter.value) &&
            dropdownFilter.value.length
          ) {
            // remove reactivity
            params[dropdownFilter.name] = [...dropdownFilter.value]
          }

          // single value drop downs
          if (!dropdownFilter.multiple && dropdownFilter.value) {
            params[dropdownFilter.name] = dropdownFilter.value
          }
        })
      }

      return params
    },

    plusPass: (state: SearchState): PlusPass | null => {
      if (state.init.plusPass && state.init.plusPass.id) {
        return state.init.plusPass
      }

      return null
    },

    cheapestMonthlyPrice: (state: SearchState): string => {
      return state.init.cheapestMonthlyPrice || ''
    },
  },

  actions: {
    setSearchInit(init: SearchInit) {
      this.init = init
    },

    setKeywordsAndSort(keywords: string, sort: string = 'relevance'): void {
      this.setKeywords(keywords)
      this.setSortSelected(sort)
    },

    clearKeywords(): void {
      this.setKeywords('')

      if (this.sortSelected === 'relevance') {
        this.setSortSelected(this.sortDefault)
      }
    },

    clearAllControls(): void {
      // remove all tagItems from tags[]
      this.clearAllTags()
      // clear in state for checkboxes, ranges and keywords
      this.clearAllCheckboxes()
      this.clearAllRanges()
      this.clearAllDropdowns()
      this.clearKeywords()
    },

    // called on load after query strings are written to
    // dropdownFilters, checkboxFilters, rangeFilters, keywords
    // assumes state has already been updated
    // also called on route change to reset tags
    setInitialTags(): void {
      const tags: SearchTag[] = []

      // create tag for keyword
      if (this.hasKeywords) {
        tags.push(this.keywordsTagItem)
      }

      // create tags for numeric ranges
      if (this.rangeFilters) {
        Object.entries(this.rangeFilters).forEach(([key, rangeFilter]) => {
          // note: maybe should make sure a number and not 0
          if (rangeFilter.min.value !== '' || rangeFilter.max.value !== '') {
            tags.push(
              this.rangeTagItem({
                filterName: rangeFilter.name,
                minValue: rangeFilter.min.value,
                maxValue: rangeFilter.max.value,
                units: rangeFilter.units,
              }),
            )
          }
        })
      }
      // end range

      // create tags for checkboxes
      if (this.checkboxFilters) {
        Object.keys(this.checkboxFilters).filter((key) => {
          if (this.checkboxFilters && this.checkboxFilters[key]) {
            this.checkboxFilters[key].items.filter((item) => {
              if (item.state) {
                tags.push(this.checkboxTagItem(item))
              }
            })
          }
        })
      }
      // end checkboxes

      // tags for dropdowns
      if (this.dropdownFilters) {
        Object.entries(this.dropdownFilters).filter(([key, dropdown]) => {
          // process values if set
          if (dropdown && dropdown.value) {
            // to support multiples, force even singles values into an array for
            // the purposes of adding tags
            const values = Array.isArray(dropdown.value) ? dropdown.value : [dropdown.value]

            // push values to tags
            values.forEach((value: any) => {
              if (!value) {
                return
              }

              // more specific id if multiple supported
              const id = dropdown.multiple ? `${key}_${value}` : key

              // restrict to dropdown items if set, otherwise
              // add the value as-is to the tag list
              if (dropdown.items) {
                // find the dropdown item
                const selectedOption = dropdown.items.find(
                  (item: any) => item.value === dropdown.value,
                )

                // add tag
                if (selectedOption) {
                  tags.push(
                    this.dropdownTagItem({
                      id,
                      filterName: key,
                      tagLabel: selectedOption.label,
                      value: selectedOption.value,
                    }),
                  )
                }
              } else {
                // add value as-is as a tag
                tags.push(
                  this.dropdownTagItem({
                    id,
                    filterName: key,
                    tagLabel: value,
                    value: value,
                  }),
                )
              }
            })
            // end push to tags
          }
        })
      }
      // end dropdowns

      this.setTags(tags)
    },

    // called when change from checkboxes, ranges or keywords
    handleTagUpdate(tag: SearchTag): void {
      // checkbox or range can have state false/blank and need to be removed (keyword doesn't get here)
      if (!tag.state) {
        this.removeTag(tag)
        return
      }

      const foundTag = this.tags.some((item) => item.id === tag.id)

      if (foundTag) {
        this.replaceTag(tag)
        return
      }

      this.addTag(tag)
    },

    // MUTATIONS
    setKeywords(keywords: string): void {
      this.keywords = keywords
    },

    setSortOptions(sortOptions: SortOption[]): void {
      this.sortOptions = sortOptions
    },

    setSortSelected(sortSelected: string): void {
      this.sortSelected = sortSelected
    },

    setSortDefault(sortOption: string): void {
      this.sortDefault = sortOption
    },

    // used just on similar videos currently
    setSimilarToId(entityId: number): void {
      this.similarToId = entityId
    },

    setClearSurpriseMe(clear: boolean): void {
      this.clearSurpriseMe = clear
    },

    // just used on initial load.
    setTags(tags: SearchTag[]): void {
      this.tags = tags
    },

    addTag(tag: SearchTag): void {
      this.tags.push(tag)
    },

    replaceTag(tag: SearchTag): void {
      // have already verified that tag exists in tags before calling replaceTag
      const tagIndex = this.tags.findIndex(function (item, i) {
        return item.id === tag.id
      })

      this.tags[tagIndex] = tag
      // replace entire array because of reactivity with Vue, doesn't see attribute change
      // could possibly use Vue.set or write this in a cleaner way
      this.tags = [...this.tags]
      // note: really just the tagLabel is being update so possibly could change to
      // have an argument of tagLabel rather than tag
      // state.tags[tagIndex].tagLabel = tagLabel
    },

    // removes tag from [] but doesn't delete the object (not sure if an issue)
    // could really pass in tag.id rather than tag object
    removeTag(tag: SearchTag): void {
      this.tags = this.tags.filter((item) => item.id !== tag.id)
    },

    // removes tag objects from [] but doesn't delete the tag object (not sure if an issue)
    clearAllTags(): void {
      this.tags = []
    },

    /**
     * Init, format, and store checkboxFilters in pinia.
     */
    setCheckboxFilters({
      checkboxFilters,
      queryStringFilters,
      checkboxUrlParameterNames,
      init,
    }: {
      checkboxFilters: Record<string, InitCheckboxFilter>
      queryStringFilters: QueryStringFilters
      checkboxUrlParameterNames: Record<string, CheckboxUrlParameterName>
      init: boolean
    }): void {
      // initialize or mutate existing based on init
      let checkboxFiltersFormatted = this.checkboxFilters

      if (init || !checkboxFiltersFormatted) {
        // first time
        checkboxFiltersFormatted = Object.entries(checkboxFilters).reduce<
          Record<string, CheckboxFilter>
        >((acc, [filterName, checkboxFilter]) => {
          acc[filterName] = {
            ...checkboxFilter,
            items: checkboxFilter.items.map(
              (item: InitCheckboxFilterItem | CheckboxFilterItem) => ({
                ...item,
                id: 'id' in item ? item.id : filterName + '_' + item.value,
                filterName: filterName,
                counter: 'counter' in item ? item.counter : 0,
                state: 'state' in item ? item.state : '', // reset on init
                tagLabel: item.label,
                type: 'checkbox',
              }),
            ),
          }

          return acc
        }, {})
      } else {
        // reset state only
        this.clearAllCheckboxes()
      }
      // end init

      // remove queryStringFilters keys that aren't in checkboxUrlParameterNames
      queryStringFilters = Object.keys(queryStringFilters)
        .filter((key) => Object.keys(checkboxUrlParameterNames).includes(key))
        .reduce((obj: any, key) => {
          obj[key] = queryStringFilters[key]
          return obj
        }, {})

      // update state based on queryStringFilters (include vs exclude)
      // for (const key in queryStringFilters) {
      Object.entries(queryStringFilters).forEach(([key, qFilter]) => {
        const { state, key: checkboxFilterKey } = checkboxUrlParameterNames[key]

        // this.$route.query is [] if multiple values but not if just 1
        qFilter = Array.isArray(qFilter) ? qFilter : [qFilter]

        // set filter.state to true for those in query string
        if (checkboxFiltersFormatted) {
          checkboxFiltersFormatted[checkboxFilterKey].items.map((checkboxItem) => {
            if (qFilter.includes(`${checkboxItem.value}`)) {
              checkboxItem.state = state
            }

            return checkboxItem
          })
        }
      })

      this.checkboxFilters = checkboxFiltersFormatted
    },

    /**
     * Init, format, and store rangeFilters in pinia.
     */
    setRangeFilters({
      rangeFilters,
      queryStringFilters,
      rangeUrlParameterNames,
      init,
    }: {
      rangeFilters: Record<string, RangeFilter>
      queryStringFilters: QueryStringFilters
      rangeUrlParameterNames: Record<string, RangeUrlParameterName>
      init: boolean
    }): void {
      // force default values
      // initialize or mutate existing based on init
      let rangeFiltersFormatted = this.rangeFilters

      if (init || !rangeFiltersFormatted) {
        // first time
        rangeFiltersFormatted = Object.entries(rangeFilters).reduce<Record<string, RangeFilter>>(
          (acc, [filterName, rangeFilter]) => {
            acc[filterName] = {
              ...rangeFilter,
              min: {
                ...rangeFilter.min,
                value: '',
              },
              max: {
                ...rangeFilter.max,
                value: '',
              },
            }

            return acc
          },
          {},
        )
      } else {
        // reset state only
        this.clearAllRanges()
      }
      // end init

      // rebuild queryStringFilters, removing keys that we don't support
      queryStringFilters = Object.keys(queryStringFilters)
        .filter((key) => Object.keys(rangeUrlParameterNames).includes(key))
        .reduce((obj: QueryStringFilters, key) => {
          obj[key] = queryStringFilters[key]
          return obj
        }, {})

      // note: we aren't filtering non numeric values or values outside of our max range or negative numbers.
      // currently gets written to tag but doesn't go to textfield due to browser only showing numbers
      // doesn't break anything but definitely not good

      // map the min / max from query string to rangeFiler min / max values
      Object.entries(queryStringFilters).forEach(([queryKey, queryVal]) => {
        const rangeUrlParam = rangeUrlParameterNames[queryKey]

        if (rangeUrlParam && rangeFiltersFormatted) {
          const rangeFilter = rangeFiltersFormatted[rangeUrlParam.filterName]

          if (rangeUrlParam.field === 'min' || rangeUrlParam.field === 'max') {
            rangeFilter[rangeUrlParam.field].value =
              queryVal && !Array.isArray(queryVal) ? queryVal : ''
          }
        }
      })

      this.rangeFilters = rangeFiltersFormatted
    },

    /**
     * Init, format, and store dropdownFilters in pinia.
     *
     * Note: multiple true for array values, multiple false for single value
     */
    setDropdownFilters({
      dropdownFilters,
      queryStringFilters,
      dropdownUrlParameterNames,
      init,
    }: {
      dropdownFilters: Record<string, DropdownFilter>
      queryStringFilters: QueryStringFilters
      dropdownUrlParameterNames: Record<string, string>
      init: boolean
    }): void {
      // force default values
      // initialize or mutate existing based on init
      let dropdownFiltersFormatted = this.dropdownFilters

      if (init || !dropdownFiltersFormatted) {
        // first time
        dropdownFiltersFormatted = Object.entries(dropdownFilters).reduce<
          Record<string, DropdownFilter>
        >((acc, [filterName, dropdownFilter]) => {
          acc[filterName] = {
            ...dropdownFilter,
            // multiple vs single init
            value: dropdownFilter.multiple ? [] : '',
          }

          return acc
        }, {})
      } else {
        // reset state only
        this.clearAllDropdowns()
      }
      // end init

      // remove filter query params/keys that we don't support
      queryStringFilters = Object.keys(queryStringFilters)
        .filter((key) => Object.keys(dropdownUrlParameterNames).indexOf(key) >= 0)
        .reduce((obj: any, key) => {
          obj[key] = queryStringFilters[key]
          return obj
        }, {})

      // update values based on query string filters
      Object.keys(queryStringFilters).forEach((key) => {
        const dropdownKey = dropdownUrlParameterNames[key]
        const queryStringValue = queryStringFilters[key]

        if (dropdownFiltersFormatted && dropdownFiltersFormatted[dropdownKey]) {
          const dropdown = dropdownFiltersFormatted[dropdownKey]

          if (dropdown.multiple && Array.isArray(queryStringValue)) {
            dropdown.value = queryStringValue ? queryStringValue : []
          } else {
            dropdown.value = queryStringValue ? queryStringValue : ''
          }
        }
      })

      this.dropdownFilters = dropdownFiltersFormatted
    },

    clearCheckbox({ filterName, id }: { filterName: string; id: string }): void {
      // we could call this directly as we have a pointer, but alas,
      // we do it the pinia way
      // checkboxItem.state = ''

      if (this.checkboxFilters && filterName) {
        const foundItem = this.checkboxFilters[filterName].items.find(
          (item: CheckboxFilterItem) => item.id === id,
        )

        if (foundItem) {
          foundItem.state = ''
        }
      }
    },

    clearAllCheckboxes(): void {
      if (!this.checkboxFilters) {
        return
      }

      Object.entries(this.checkboxFilters).forEach(([key, checkboxFilter]) => {
        checkboxFilter.items.forEach((item: CheckboxFilterItem) => {
          item.state = ''
          return item
        })
      })
    },

    // name is currently duration or calories
    clearRange({ filterName }: { filterName: string }): void {
      if (!this.rangeFilters || !this.rangeFilters[filterName]) {
        return
      }

      this.rangeFilters[filterName].min.value = ''
      this.rangeFilters[filterName].max.value = ''
    },

    clearAllRanges(): void {
      if (!this.rangeFilters) {
        return
      }

      Object.entries(this.rangeFilters).forEach(([key, rangeFilter]) => {
        rangeFilter.min.value = ''
        rangeFilter.max.value = ''
      })
    },

    // single and multiple support
    clearDropdown({ filterName, value }: { filterName: string; value?: string | number }): void {
      if (!this.dropdownFilters || !this.dropdownFilters[filterName]) {
        return
      }

      // lookup dropdownFilter key via the dropdownUrlParameterNames mapper
      // as dropdownItem.name could be different than dropdownFilter.name
      const dropdownFilter = this.dropdownFilters[filterName]

      if (dropdownFilter && dropdownFilter.multiple && Array.isArray(dropdownFilter.value)) {
        // if multiple, just remove one of the values
        dropdownFilter.value = dropdownFilter.value.filter(
          (dropdownValue) => dropdownValue !== value,
        )
      } else if (dropdownFilter) {
        // single value, just clear
        dropdownFilter.value = ''
      }
    },

    clearAllDropdowns(): void {
      if (!this.dropdownFilters) {
        return
      }

      Object.entries(this.dropdownFilters).forEach(([key, dropdownFilter]) => {
        // single vs multiple support
        dropdownFilter.value = dropdownFilter.multiple ? [] : ''
      })
    },

    setAllowEmptyFacets(allowEmpty: boolean): void {
      this.allowEmptyFacets = allowEmpty
    },

    // called on initial load and on each api call
    // sets state.enableFacets as well as updating state.checkboxFilters[].items[].counter
    updateFacets(newFacets?: Record<string, Record<string | number, number>> | null): void {
      // this happens when server is using MySQL or something that doesn't support facets
      if (!newFacets) {
        this.enableFacets = false
        return
      }

      this.enableFacets = true

      if (!this.checkboxFilters) {
        return
      }

      // update checkbox filters with new counts base on facet counts
      Object.entries(this.checkboxFilters).forEach(([key, checkboxFilter]) => {
        // set 0 if facet type is undefined
        // Opensearch only returns items that have values > 0, so this is a standard response
        if (!newFacets[key]) {
          checkboxFilter.items.forEach((item) => {
            item.counter = 0
          })
          return
        }

        checkboxFilter.items.forEach((item) => {
          item.counter = newFacets[key][item.value] || 0
        })
      })
    },

    // checkbox object has filterName, key and state to be able to set state for it
    setCheckboxState(checkbox: { filterName: string; id: string; state: TriStateCheckboxState }) {
      // skip if not set
      if (!this.checkboxFilters || !this.checkboxFilters[checkbox.filterName]) {
        return
      }

      const foundItem = this.checkboxFilters[checkbox.filterName].items.find(
        (item) => item.id === checkbox.id,
      )

      if (foundItem) {
        foundItem.state = checkbox.state
      }
    },

    setMultipleDropdownValue({ filterName, value }: { filterName: string; value: string[] }): void {
      // skip if not set
      if (!this.dropdownFilters || !this.dropdownFilters[filterName]) {
        return
      }

      const dropdownFilter = this.dropdownFilters[filterName]

      if (!dropdownFilter.multiple || !Array.isArray(value)) {
        return
      }

      dropdownFilter.value = value
    },

    setDropdownValue({ filterName, value }: { filterName: string; value: string }): void {
      // skip if not set
      if (!this.dropdownFilters || !this.dropdownFilters[filterName]) {
        return
      }

      const dropdownFilter = this.dropdownFilters[filterName]

      // append value if multiple
      if (dropdownFilter.multiple && Array.isArray(dropdownFilter.value)) {
        dropdownFilter.value = [...dropdownFilter.value, value]
        return
      }

      // single value support
      dropdownFilter.value = value
    },

    addDropdownItem({
      filterName,
      value,
      label,
    }: {
      filterName: string
      value: string
      label: string
    }): void {
      // skip if not set
      if (
        !this.dropdownFilters ||
        !this.dropdownFilters[filterName] ||
        !Array.isArray(this.dropdownFilters[filterName].items)
      ) {
        return
      }

      // add as first item
      const currItems = this.dropdownFilters[filterName].items || []

      this.dropdownFilters[filterName].items = [{ value, label }, ...currItems]
    },

    updateDropdownItemLabel({
      filterName,
      value,
      label,
    }: {
      filterName: string
      value: string
      label: string
    }): void {
      // skip if not set
      if (
        !this.dropdownFilters ||
        !this.dropdownFilters[filterName] ||
        !Array.isArray(this.dropdownFilters[filterName].items)
      ) {
        return
      }

      const foundItem = this.dropdownFilters[filterName].items?.find((item) => item.value === value)

      if (foundItem) {
        foundItem.label = label
      }
    },

    deleteDropdownItem({ filterName, value }: { filterName: string; value: string }): void {
      // skip if not set
      if (
        !this.dropdownFilters ||
        !this.dropdownFilters[filterName] ||
        !Array.isArray(this.dropdownFilters[filterName].items)
      ) {
        return
      }

      // remove from items
      this.dropdownFilters[filterName].items = this.dropdownFilters[filterName].items?.filter(
        (item) => item.value !== value,
      )

      // reset if value was also selected
      if (this.dropdownFilters[filterName].value === value) {
        this.dropdownFilters[filterName].value = ''

        // remove tag
        this.removeTag({
          filterName,
          id: filterName,
          tagLabel: this.dropdownFilters[filterName].label,
          state: true,
          type: 'dropdown',
        })
      }
    },

    setRangeMinMaxValue({
      filterName,
      minValue,
      maxValue,
    }: {
      filterName: string
      minValue: string
      maxValue: string
    }): void {
      // skip if not set
      if (!this.rangeFilters || !this.rangeFilters[filterName]) {
        return
      }

      this.rangeFilters[filterName].min.value = minValue
      this.rangeFilters[filterName].max.value = maxValue
    },

    // stand alone helper to build checkbox include / exclude param mapping (checkboxes)
    // EX: teammember: { key: 'team_member_ids', state: 'include' },
    // EX: xteammember: { key: 'team_member_ids', state: 'exclude' },
    createIncludeExcludeParamMap(
      checkboxMap: Record<string, string>,
      processExcludeFilters: boolean = false,
    ): Record<string, CheckboxUrlParameterName> {
      return Object.entries(checkboxMap).reduce<Record<string, CheckboxUrlParameterName>>(
        (acc, [paramKey, checkboxFilterKey]) => {
          // include
          acc[paramKey] = { key: checkboxFilterKey, state: 'include' }

          // exclude
          if (processExcludeFilters) {
            acc[`x${paramKey}`] = { key: checkboxFilterKey, state: 'exclude' }
          }

          return acc
        },
        {},
      )
    },

    setFiltersOpen(open: boolean): void {
      this.filtersOpen = open
    },

    // init or replace entire object, useful for expand / collapse all
    setFilterGroupingsExpanded(init: Record<string, boolean>): void {
      this.filterGroupingsExpanded = init
    },

    // update single expander
    updateFilterGroupingsExpanded({
      groupingId,
      isExpanded,
    }: {
      groupingId: string
      isExpanded: boolean
    }): void {
      this.filterGroupingsExpanded[groupingId] = isExpanded
    },
  },
})
