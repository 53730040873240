<template>
  <modal
    :heading="modalHeading"
    modal-class=""
    :body-class="`modal__body -tight ${screen === 'form' ? '-left' : ''}`"
    :show-footer="false"
    @close="emit('close')"
  >
    <!-- statuses -->
    <callout v-if="error" type="error">
      <div>{{ error }}</div>
    </callout>

    <!-- screens -->
    <vee-form v-if="screen === 'form'" v-slot="{ validate }" as="">
      <form
        ref="filterCollectionForm"
        class="standard-form"
        name="filterCollectionForm"
        @submit.prevent="handleAddEditSubmit(validate)"
      >
        <text-input
          v-model="formData.title"
          input-id="title"
          label="Title"
          placeholder="My Collection Title"
          rules="required|max:100"
          maxlength="100"
        />
        <select-input
          v-model="formData.privacy"
          input-id="privacy"
          label="Privacy"
          rules="required"
          :options="privacyOptions"
        >
          <template #note>
            <div class="input-note">
              <p
                v-if="formData.privacy === 'private' && formData.canBeMadePublic"
                class="small input-note"
              >
                <strong>Note</strong>: Private collections are not sharable.
              </p>
              <p
                v-if="formData.privacy === 'private' && !formData.canBeMadePublic"
                class="small input-note"
              >
                <span v-if="!formData.canBeMadePublic">
                  <strong>Note</strong>: This collection is Private by default because you're using
                  personal filters that other members won't be able to see. If you want to make this
                  collection public, you'll need to remove the personal filters:
                  {{ privateFilters }}.
                </span>
              </p>
              <p v-else-if="formData.privacy === 'public'" class="small input-note">
                <strong>Note</strong>: Public filter collections can be shared with your friends and
                other users.
              </p>
            </div>
          </template>
        </select-input>

        <div class="action__group form-actions -flex -center">
          <div class="form-action">
            <button class="btn -dark -no-case" @click.prevent="handleAddEditSubmit(validate)">
              {{ processing ? 'Saving...' : 'Save' }}
            </button>
          </div>
        </div>
      </form>
    </vee-form>
    <div v-else-if="screen === 'success'">
      <div class="modal-success" aria-label="Success">
        <i class="material-symbols-outlined fill" data-icon="check_circle" aria-hidden="true"></i>
      </div>

      <div v-if="!isEdit" class="modal-actions -flex -center">
        <router-link to="/videos/browse" class="btn -dark -no-case" @click="emit('close')">
          View Collections
        </router-link>
      </div>
    </div>
    <div v-else-if="screen === 'copy'">
      <div class="form-actions -column">
        <div class="form-action">
          <button class="btn -dark -no-case" @click.prevent="handleCopy">
            {{ processing ? 'Copying...' : 'Save Copy' }}
          </button>
        </div>
        <div class="form-action">
          <router-link
            v-if="filterCollection.filterUrl"
            :to="filterCollection.filterUrl"
            @click="emit('close')"
            >View or edit filters before saving collection</router-link
          >
        </div>
      </div>
    </div>
    <div v-else-if="screen === 'share'">
      <p>
        Share these {{ filterCollection.typeName?.toLowerCase() }} with your friends and family.
      </p>
      <div v-if="processing" class="share-link -skeleton">
        <div class="qr-code"></div>
        <div class="link"></div>
      </div>
      <div v-else-if="!processing && !error" class="share-link">
        <div class="qr-code">
          <img :src="formData.image" alt="QR Code" width="150" height="150" />
        </div>
        <div class="">
          <a class="link" :href="formData.shareUrl">{{ formData.shareUrl }}</a>
        </div>
      </div>
    </div>
  </modal>
</template>

<script setup lang="ts">
/**
 * Shared modal that handles the following filter collection use cases. Kept as one component
 * instead of separate ones to keep header-modals.vue slim.
 * - new filter collection form
 * - edit filter collection form
 * - share screen with QR code
 * - copy confirmation
 * - success message screen
 */
import { computed, onBeforeMount, ref } from 'vue'
import { Form as VeeForm } from 'vee-validate'
import Modal from '@/components/modals/modal.vue'
import type { FilterCollection } from '@/types/model-types'
import TextInput from '@/components/forms/text-input.vue'
import SelectInput from '@/components/forms/select-input.vue'
import { fbApi, FbApiError } from '@/utils/http-api'
import Callout from '@/components/notifications/callout.vue'
import { useSearchStore } from '@/stores/search'

/** TYPES **/
type Screen = 'form' | 'success' | 'share' | 'copy'

/** PROPS **/
const props = withDefaults(
  defineProps<{
    screen?: Screen
    filterCollection: Partial<FilterCollection>
  }>(),
  {
    screen: 'form',
  },
)

/** EMITS **/
const emit = defineEmits(['close', 'update', 'copy'])

/** STATE **/
const screen = ref<Screen>(props.screen)
const formData = ref<Partial<FilterCollection>>({
  ...props.filterCollection,
})
const processing = ref(false)
const error = ref('')

const { videoPrivateFilters } = useSearchStore()

/** COMPUTED **/
const isEdit = computed(() => !!props.filterCollection.id)

const modalHeading = computed(() => {
  if (screen.value === 'copy') {
    return 'Copy into Your Collections'
  }

  if (screen.value === 'share') {
    return 'Share Collection'
  }

  if (screen.value === 'success') {
    if (props.screen === 'copy') {
      return 'Collection Copied!'
    }

    return 'Collection Saved!'
  }

  if (isEdit.value) {
    return 'Edit Collection'
  }

  return 'Save Filters to a Collection'
})

const privacyOptions = computed(() => {
  if (props.filterCollection.canBeMadePublic) {
    return [
      { value: 'private', label: 'Private' },
      { value: 'public', label: 'Public' },
    ]
  }

  // private only
  return [{ value: 'private', label: 'Private' }]
})

const privateFilters = computed<string>(() => {
  // do nothing
  if (props.filterCollection.canBeMadePublic) {
    return ''
  }

  // TODO: filters - support other filterCollection.type
  // comma separate human readable list
  const privateFilterMap = videoPrivateFilters()

  return Object.keys(privateFilterMap)
    .filter((key) => {
      if (!props.filterCollection.queryParams) {
        return false
      }

      return key in props.filterCollection.queryParams
    })
    .map((key) => privateFilterMap[key as keyof typeof privateFilterMap])
    .join(', ')
})

/** LIFECYCLE **/
onBeforeMount(() => {
  if (
    props.screen === 'share' &&
    props.filterCollection?.privacy === 'public' &&
    !props.filterCollection?.image
  ) {
    generateShareCode()
  }
})

/** METHODS **/

/**
 * Handle add and edit form submissions.
 */
async function handleAddEditSubmit(validate: Function) {
  // no double submit
  if (processing.value) {
    return
  }

  // reset
  processing.value = true
  error.value = ''

  try {
    const results = await validate()

    if (!results.valid) {
      processing.value = false
      return
    }

    // create vs edit, submitting with underscores
    let res = null

    if (isEdit.value) {
      // edit
      res = await fbApi.put(`/filter-collections/${props.filterCollection.id}`, {
        json: {
          title: formData.value.title,
          privacy: formData.value.privacy,
        },
        skipErrorModal: true,
      })
    } else {
      // add
      res = await fbApi.post('/filter-collections', {
        json: {
          type: formData.value.type,
          title: formData.value.title,
          privacy: formData.value.privacy,
          query_params: formData.value.queryParams,
        },
        skipErrorModal: true,
      })
    }

    if (!res || !res.body || !res.body.data) {
      throw Error('Missing response')
    }

    // success
    screen.value = 'success'

    // pass back up to store
    if (isEdit.value) {
      emit('update', res.body.data)
    }
  } catch (e) {
    console.error(e)

    // default error
    error.value =
      'There was an error saving your collection. Please refresh the page and try again.'

    // most likely collection was private, shouldn't happen unless long tab is open
    if (e instanceof FbApiError && e.status === 400) {
      error.value = e.body.message
    }
  } finally {
    processing.value = false
  }
}

/**
 * Handle generating the share code for a public collection.
 */
async function generateShareCode() {
  // no double submit
  if (processing.value) {
    return
  }

  // reset
  processing.value = true
  error.value = ''

  try {
    const res = await fbApi.put(`/filter-collections/${props.filterCollection.id}/share`, {
      skipErrorModal: true,
    })

    if (!res || !res.body || !res.body.data) {
      throw Error('Missing response')
    }

    // update filter collection
    formData.value = res.body.data
  } catch (e) {
    console.error(e)

    // default error
    error.value =
      'There was an error generating the collection share code. Please refresh the page and try again.'

    // most likely collection was private, shouldn't happen unless long tab is open
    if (e instanceof FbApiError && e.status === 400) {
      error.value = e.body.message
    }
  } finally {
    processing.value = false
  }
}

/**
 * Save a copy
 */
async function handleCopy() {
  // no double submit
  if (processing.value) {
    return
  }

  // reset
  processing.value = true
  error.value = ''

  try {
    const res = await fbApi.put(`/filter-collections/${props.filterCollection.id}/copy`, {
      skipErrorModal: true,
    })

    if (!res || !res.body || !res.body.data) {
      throw Error('Missing response')
    }

    // success
    screen.value = 'success'

    // update filter collection
    emit('copy', res.body.data)
  } catch (e) {
    console.error(e)

    // default error
    error.value =
      'There was an error copying the collection. Please refresh the page and try again.'
  } finally {
    processing.value = false
  }
}
</script>
