import { fbApi } from '@/utils/http-api'
import { useUserAuthStore } from '@/stores/user-auth'
import { useCompletesStore } from '@/stores/completes'
import { useFavoritesStore } from '@/stores/favorites'
import { useShelvesStore } from '@/stores/shelves'
import { useCalendarStore } from '@/stores/calendar'

/**
 * Initialize stores with data that is required and many views to power cards
 * and icon states.
 */
export async function userStoresInit() {
  try {
    const userAuthStore = useUserAuthStore()
    const completesStore = useCompletesStore()
    const favoritesStore = useFavoritesStore()
    const shelvesStore = useShelvesStore()
    const calendarStore = useCalendarStore()

    // skip if anon user
    if (!userAuthStore.user.isLoggedIn) {
      return
    }

    // skip if fb admin
    if (userAuthStore.isUserFbAdmin) {
      console.log('skipping userStoresInit for fb admin')
      return
    }

    // do not show error modal at all if failed
    const res = await fbApi.get('/my/stores', {
      skipErrorModal: true,
    })

    if (!res || !res.body || !res.body.data) {
      throw new Error(`Missing store data on success response`)
    }

    const { completes, favorites, scheduledPlansStats, shelfItems, purchasedPlanIds } =
      res.body.data

    if (completes) {
      completesStore.setCompletes(completes)
    }

    if (favorites) {
      favoritesStore.setFavorites(favorites)
    }

    if (scheduledPlansStats) {
      calendarStore.setScheduledPlansStats(scheduledPlansStats)
    }

    if (shelfItems) {
      shelvesStore.setShelfItems(shelfItems)
    }

    if (purchasedPlanIds) {
      userAuthStore.setPlanPurchases(purchasedPlanIds)
    }
  } catch (e) {
    console.error(e)
  }
}
